<template>
	<main class="main">
		<div class="d-flex">
			<div class="search-area">
				<h2>Search</h2>
				<div class="w-100 mb-1">
					<label> Destination/Property Name: </label>
					<div class="search-input d-flex align-items-center ps-2">
						<svg aria-hidden="true" fill="#333333" focusable="false" height="20" role="presentation" width="20"
							viewBox="0 0 24 24">
							<path
								d="M17.464 6.56a8.313 8.313 0 1 1-15.302 6.504A8.313 8.313 0 0 1 17.464 6.56zm1.38-.586C16.724.986 10.963-1.339 5.974.781.988 2.9-1.337 8.662.783 13.65c2.12 4.987 7.881 7.312 12.87 5.192 4.987-2.12 7.312-7.881 5.192-12.87zM15.691 16.75l7.029 7.03a.75.75 0 0 0 1.06-1.06l-7.029-7.03a.75.75 0 0 0-1.06 1.06z">
							</path>
						</svg>
						<div class="result ms-3" v-if="guestData.location">{{ guestData.location }}</div>
					</div>
				</div>
				<div class="w-100 mb-1">
					<label> Check-in date </label>
					<div class="search-input d-flex align-items-center ps-2">
						<svg aria-hidden="true" fill="#333333" focusable="false" height="20" role="presentation" width="20"
							viewBox="0 0 24 24">
							<path
								d="M22.502 13.5v8.25a.75.75 0 0 1-.75.75h-19.5a.75.75 0 0 1-.75-.75V5.25a.75.75 0 0 1 .75-.75h19.5a.75.75 0 0 1 .75.75v8.25zm1.5 0V5.25A2.25 2.25 0 0 0 21.752 3h-19.5a2.25 2.25 0 0 0-2.25 2.25v16.5A2.25 2.25 0 0 0 2.252 24h19.5a2.25 2.25 0 0 0 2.25-2.25V13.5zm-23.25-3h22.5a.75.75 0 0 0 0-1.5H.752a.75.75 0 0 0 0 1.5zM7.502 6V.75a.75.75 0 0 0-1.5 0V6a.75.75 0 0 0 1.5 0zm10.5 0V.75a.75.75 0 0 0-1.5 0V6a.75.75 0 0 0 1.5 0z">
							</path>
						</svg>
						<div class="result ms-3" v-if="guestData.checkinDate">{{ guestData.checkinDate }}</div>
					</div>
				</div>
				<div class="w-100 mb-1">
					<label> Check-out date</label>
					<div class="search-input d-flex align-items-center ps-2 mb-1">
						<svg aria-hidden="true" fill="#333333" focusable="false" height="20" role="presentation" width="20"
							viewBox="0 0 24 24">
							<path
								d="M22.502 13.5v8.25a.75.75 0 0 1-.75.75h-19.5a.75.75 0 0 1-.75-.75V5.25a.75.75 0 0 1 .75-.75h19.5a.75.75 0 0 1 .75.75v8.25zm1.5 0V5.25A2.25 2.25 0 0 0 21.752 3h-19.5a2.25 2.25 0 0 0-2.25 2.25v16.5A2.25 2.25 0 0 0 2.252 24h19.5a2.25 2.25 0 0 0 2.25-2.25V13.5zm-23.25-3h22.5a.75.75 0 0 0 0-1.5H.752a.75.75 0 0 0 0 1.5zM7.502 6V.75a.75.75 0 0 0-1.5 0V6a.75.75 0 0 0 1.5 0zm10.5 0V.75a.75.75 0 0 0-1.5 0V6a.75.75 0 0 0 1.5 0z">
							</path>
						</svg>
						<div class="result ms-3" v-if="guestData.checkoutDate">{{ guestData.checkoutDate }}</div>
					</div>
					<small class="d-block" v-if="guestData.days">{{ guestData.days }} night stay</small>
				</div>
				<div class="w-100 mb-2">
					<div class="result search-input d-flex align-items-center ps-2" v-if="guestData.adult">
						{{ guestData.adult }} adults
					</div>
				</div>
				<div class="d-flex gap-2 w-100 mb-2">
					<div class="result search-input w-50 d-flex align-items-center ps-2" v-if="guestData.children">
						{{ guestData.children }} children
					</div>
					<div class="result search-input w-50 d-flex align-items-center ps-2" v-if="guestData.room">
						{{ guestData.room }} room
					</div>
				</div>
				<router-link :to="{ name: 'home' }">
					<el-button icon="el-icon-search" class="search-btn">Search</el-button>
				</router-link>
				<br>
				<br>
				<br>
				<img src="../assets/map.png" width="230px" height="180px" />
			</div>

			<!-- Hotel details information is shown here(name, stars, adress)-->
			<div style="margin-left:30px">
				<div class="d-flex">
					<h4 class="hotel-name m-0 me-2" v-if="hotel">{{ hotel }}</h4>
					<Star class="m-0" :num="rating" />
				</div>
				<p class="hotel-location" v-if="address">
					<i style="color: #1677cc; font-size: 20px" class="fa fa-map-marker me-2" aria-hidden="true"></i>{{
							address
					}}
				</p>
				<div>
					<img style="padding:5px" width="721px" height="400px" :src="`${host}/${img1}`">
					<div style="float:right">
						<img style="padding:5px" width="374px" height="200px" :src="`${host}/${img2}`">
						<br>
						<img style="padding:5px" width="374px" height="200px" :src="`${host}/${img3}`">
					</div>
					<div class="d-flex" style="margin-top:5px">
						<img style="padding:5px" width="220px" height="170px" :src="`${host}/${img4}`">
						<img style="padding:5px" width="220px" height="170px" :src="`${host}/${img5}`">
						<img style="padding:5px" width="220px" height="170px" :src="`${host}/${img6}`">
						<img style="padding:5px" width="220px" height="170px" :src="`${host}/${img7}`">
						<div class="abc">
							<br>
							<br>
							<span v-if="noofimages">+{{ noofimages }} photos</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<br />
		<div style="display: flex;">
			<el-table :data="data" border size="small" height="550" style="width: 100%;"
				@selection-change="handleSelectionChange">

				<el-table-column prop="RoomType" label="Room Type" align="center" :min-width="150">
					<template slot-scope="scope">
						<el-input v-model="scope.row[scope.column.property]" :disabled="scope.row.edited"> </el-input><br>

						<span style="color: darkblue; text-align: left; font-size: 12px;" v-for="(n, i) in room1" :key="i"> {{ n }}
						</span>
					</template>
				</el-table-column>
				<el-table-column prop="RoomQuality" label="Room Level" align="center" :min-width="80">

				</el-table-column>
				<el-table-column prop="RoomSize" label="Room Size" align="center" :min-width="75">

				</el-table-column>
				<el-table-column prop="BedType" label="Bed Type" align="center" :min-width="80">
				</el-table-column>
				<el-table-column prop="View" label="View" align="center" :min-width="100">

				</el-table-column>
				<el-table-column prop="Price" label="Price (AED)" align="center" :min-width="75">

				</el-table-column>
				<el-table-column prop="NoOfPerson" label="No Of Persons" align="center" :min-width="80">

				</el-table-column>
				<el-table-column prop="RoomQuantity" label="Room Quantity" align="center" :min-width="82">
					<template slot-scope="scope">
						<select name="quantity" v-model="scope.row[scope.column.property]" :disabled="false"
							@change="SelectQ(scope.$index, $event)">
							<option v-for="n in 10" :key="n" :value="n">{{ n }} </option>
						</select>
					</template>
				</el-table-column>

				<el-table-column prop="RoomBasis" label="Room Basis" align="center" :min-width="90">
					<template slot-scope="scope">
						<select name="RB" v-model="scope.row[scope.column.property]" @change="SelectRb(scope.$index, $event)">
							<option v-for="(n, i) in roombasis" :key="i" :value="n">{{ n }} </option>
						</select>
					</template>
				</el-table-column>
				<el-table-column type="selection" width="50" align="center" />
				<!--<router-link
					:to="{
						name: 'Reservation',
						params: { selectedHotel: selectedHotel },
					}"
				><el-button type="secondary">Reserve</el-button>
				</router-link>-->

			</el-table>

			<el-table border size="small" style="width: 10%; font-weight: bold;" height="550" :data="total_table">
				<el-table-column label="" align="center" :min-width="10">{{ total | dollarSign }}
					<el-button size="small" type="primary" @click="focusElement">Reserve</el-button>
				</el-table-column>
			</el-table>
		</div>
		<br>
		<!-- Shows the booking details(checkin date, checkout date, days, room number, price) -->
		<div class="w-50">
			<h2 class="heading">Booking Details</h2>
			<div class="
						booking-details
						w-100
						p-3
						d-flex
						align-items-start
						justify-content-between
						mb-3
					">
				<div>
					<p class="m-0 details-heading">Check-in Date</p>
					<p class="m-0 details-body" v-if="guestData.checkinDate">{{ guestData.checkinDate }}</p>
					<small class="m-0">From 3:00 PM</small>
				</div>
				<div>
					<p class="m-0 details-heading">Check-out Date</p>
					<p class="m-0 details-body" v-if="guestData.checkoutDate">{{ guestData.checkoutDate }}</p>
					<small class="m-0">{{ guestData.days }} night(s) stay</small>
				</div>
				<div>
					<p class="m-0 details-heading">Guests</p>
					<p class="m-0 details-body" v-if="guestData.adult">{{ guestData.adult }} Adult(s)</p>
					<p class="m-0 details-body" v-if="guestData.children !== 0">
						{{ guestData.children }} Children
					</p>
				</div>
			</div>
			<div class="
						booking-details
						w-100
						p-3
						d-flex
						align-items-start
						justify-content-between
						mb-4
					">
				<div>
					<p class="m-0 details-heading">Rooms</p>
					<p class="m-0 details-body" v-if="RoomCount">{{ RoomCount }} Room(s)</p>
				</div>
				<div>
					<p class="m-0 details-heading">Room Price</p>
					<p class="m-0 details-body" v-if="total">
						{{ parseFloat(total) | dollarSign }}
					</p>
				</div>
				<div>
					<p class="m-0 details-heading">Taxes
						<el-popover placement="top-start" title="" width="300" trigger="hover"
							content="this is content, this is content, this is content">
							<el-link slot="reference" icon="el-icon-info"></el-link>
							<div style="display: flex;">
								<ul>
									<li style="font-weight:bold;">VAT:</li>
									<li style="font-weight:bold;">Taxes:</li>
									<li style="font-weight:bold;">Service Fees:</li>
									<li style="font-weight:bold;">Tourism Dirham:</li>
								</ul>
								<ul>
									<li>{{ vat }}%</li>
									<li>{{ taxes }}%</li>
									<li>{{ servicefees }}%</li>
									<li>{{ parseFloat(tourismdirham) | dollarSign }}</li>
								</ul>
							</div>
						</el-popover>
					</p>
					<p class="m-0 details-body">
						{{ tax | dollarSign }}
					</p>
				</div>
				<div>
					<p class="m-0 details-heading">Total Price</p>
					<p class="m-0 details-body">
						{{ total_price | dollarSign }}
					</p>
				</div>
			</div>

			<!-- Route for going reservation page and sending selected hotel information to the reservation page 
				<router-link
					:to="{
						name: 'Reservation',
						params: { selectedHotel: selectedHotel },
					}"
					><el-button class="book-btn rounded w-100" @click="open">Book Now</el-button>
				</router-link>
				-->
			<el-button ref="bc" class="rounded w-100" @click="open">Book Now</el-button>
		</div>

		<br>
		<div style="display: flex;">
			<div style="width:300px" v-if="hotelamenities">
				<h4 class="h_3">Amenities</h4>
				<ul v-if="hotelamenities">
					<li v-for="(n, i) in hotelamenities" :key="i"><svg viewBox="0 0 128 128">
							<path
								d="M56.33 100a4 4 0 0 1-2.82-1.16L20.68 66.12a4 4 0 1 1 5.64-5.65l29.57 29.46 45.42-60.33a4 4 0 1 1 6.38 4.8l-48.17 64a4 4 0 0 1-2.91 1.6z">
							</path>
						</svg>{{ n.amenityname }}</li>
				</ul>
			</div>

			<div style="width:300px; margin-left:230px" v-if="hotelfacilities">
				<h4 class="h_3">Facilities</h4>
				<ul>
					<li v-for="(n, i) in hotelfacilities" :key="i"><svg viewBox="0 0 128 128">
							<path
								d="M56.33 100a4 4 0 0 1-2.82-1.16L20.68 66.12a4 4 0 1 1 5.64-5.65l29.57 29.46 45.42-60.33a4 4 0 1 1 6.38 4.8l-48.17 64a4 4 0 0 1-2.91 1.6z">
							</path>
						</svg>{{ n.facilityname }}</li>
				</ul>
			</div>
			<div style="width:300px; margin-left:200px" v-if="hotelattraction">
				<h4><svg style="width:20px; height:20px"></svg>Top attractions</h4>
				<ul>
					<li v-for="(n, i) in hotelattraction" :key="i"><svg viewBox="0 0 128 128">
							<path
								d="M56.33 100a4 4 0 0 1-2.82-1.16L20.68 66.12a4 4 0 1 1 5.64-5.65l29.57 29.46 45.42-60.33a4 4 0 1 1 6.38 4.8l-48.17 64a4 4 0 0 1-2.91 1.6z">
							</path>
						</svg>{{ n.AttractionName }} - {{ n.AttractionDistance }} km</li>
				</ul>
			</div>
		</div>
		<div>
			<h3>Description</h3>
			<p>{{ description }}</p>
			<h3>Policy</h3>
			<h5>Cancellation/Change/Refund:</h5>
			<ul>
				<li style="list-style-type: circle;">Up to 2 children below 12 years old can stay in the parent's room.
					Extra bed has to be requested at the time of making the reservation. </li>
				<li style="list-style-type: circle;">If a child is above 12 years of age, a separate room will be required
					at the parent's applicable room rate. (An extra bed shall not be provided for anyone over 12 years of
					age.) </li>
				<li style="list-style-type: circle;">Extra bed supplement for children age 3 to 12 years is not mandatory
					and will be added only if it is required. </li>
				<li style="list-style-type: circle;">Breakfast for the child (if required) under the age of 6 to 12 years is
					chargeable at 50% of the normal breakfast price. </li>
			</ul>
			<h5>Maximum Occupancy per category:</h5>
			<ul>
				<li style="list-style-type: circle;">2 adults plus 2 children below 12 years old is acceptable for all room
					types (Deluxe, Luxury and Premier Rooms). </li>
				<li style="list-style-type: circle;">2 adults plus 2 children below 12 years old OR 3 adults and 1 child
					below 12 years old for Deluxe Suites and Luxury Suites. </li>
				<li style="list-style-type: circle;">Up to 6 adults and 6 children below 12 years old can be accommodated in
					a 3 bedroom suite. </li>
				<li style="list-style-type: circle;">Up to 4 adults and 4 children below 12 years old can be accommodated in
					a 2 bedroom suite. </li>
				<li style="list-style-type: circle;">Maximum of 1 extra bed is allowed in all room types including suites.
				</li>
			</ul>
		</div>

		<h4>Rating</h4>
		<div style="display: flex;">
			<div style="width:300px">
				<h5>Location</h5>
				<div style="display: flex;">
					<Star class="m-0" :num="locationRating" />
					<span style="margin-left:10px">{{ locationRating }}</span>
				</div>
			</div>
			<div style="width:300px">
				<h5>Room</h5>
				<div style="display: flex;">
					<Star class="m-0" :num="roomRating" />
					<span style="margin-left:10px">{{ roomRating }}</span>
				</div>
			</div>
			<div style="width:300px">
				<h5>Service</h5>
				<div style="display: flex;">
					<Star class="m-0" :num="serviceRating" />
					<span style="margin-left:10px">{{ serviceRating }}</span>
				</div>
			</div>
		</div>
		<br>
		<br>
	</main>
</template>

<script>
import Star from "../components/Star.vue";
import dollarSign from "../mixins/Filters";
import axios from "axios";

export default {
	name: "HotelDetails",
	data() {
		return {
			data: [],
			host: 'https://www.desertlinktours.com/api/hotelimages/',
			status: false,
			count: 0,
			RoomCount: 0,
			noofperson: 0,
			tax: 0,
			total_price: 0,
			v_rb: "Room Only ",
			//v_rq: 1,
			num: Number,
			roomamenities: [],
			roombasis: [],
			hotelamenities: [],
			hotelfacilities: [],
			hotelattraction: [],
			room1: [],
			//ra: String,
			hotel: String,
			description: String,
			selectedHotel: null,
			vat: 0,
			taxes: 0,
			servicefees: 0,
			tourismdirham: 0,
			locationRating: 0,
			roomRating: 0,
			serviceRating: 0,
			hotelid: 0,
			rating: 0,
			address: String,
			img1: "",
			img2: "",
			img3: "",
			img4: "",
			img5: "",
			img6: "",
			img7: "",
			img8: "",
			noofimages: 0,
			total: 0,
			total_table: [],
			q_i: 0,
			q_v: 1,
			bookingid: 0,
			roomdetail: Object,
			sp_checkin: "",
			sp_checkout: "",
			//normalDays: 0,
			specialDays: 0,
			agent: {},
			agtid: 0
		};
	},
	mixins: [dollarSign],
	props: {
		hotelsData: {
			type: Array,
			required: true,
		},
		name: {
			type: String,
			required: true,
		},
		guestData: { type: Object, required: true },
	},
	components: {
		Star,
	},
	// Shows the selected hotel based on the name parameter coming from router in hotels/hotel-name-here format
	created() {
		// this.selectedHotel = this.hotelsData.find(
		//   (item) => item.name.split(" ").join("-") === this.name
		// );
		this.hotel = this.name.split("-").join(" ");
		this.getHotelData(this.hotel);
		this.getRoomAmenities();
		this.total_table = [0];
		this.agent = JSON.parse(localStorage.getItem('agent'))
	 	this.agtid = this.agent["Agtid"]
	},
	methods: {
		focusElement() {
			this.$refs.bc.$el.focus();
		},
		async getHotelRoom(hotel) {
			try {
				const response = await axios.get(
					`https://www.desertlinktours.com/api/hotels/HotelRoom/${hotel}`
				);
				//setting room amenities to fit in table
				//this.ra=this.room1.toString();
				//this.ra=this.ra.split(",").join(", ")
				this.data = response.data;
			} catch (err) {
				console.log(err);
			}
		},
		async postBooking() {
			let vm = this
			try {
				await axios.post("https://www.desertlinktours.com/api/hotels/SaveBooking", {
					hotelid: vm.hotelid, checkin: vm.guestData.checkinDate, checkout: vm.guestData.checkoutDate, guests: vm.guestData.adult, child: vm.guestData.children, rooms: vm.RoomCount, price: vm.total, tax: vm.tax, total_price: vm.total_price, Agtid: vm.agtid
				});
			} catch (err) {
				console.log(err);
			}
		},
		async getBookingID() {
			try {
				const response = await axios.get(
					`https://www.desertlinktours.com/api/hotels/BookingID`
				);
				this.bookingid = response.data[0].bookingid;
				this.postRoomDetail(this.bookingid);

			} catch (err) {
				console.log(err);
			}
		},
		async postRoomDetail(bid) {
			try {
				await axios.post("https://www.desertlinktours.com/api/hotels/SaveRoomDetail", {
					roomdetail: this.roomdetail, bookingid: bid, len: this.roomdetail.length
				});
			} catch (err) {
				console.log(err);
			}
		},
		async getHotelData(hotel) {
			try {
				const response = await axios.get(
					`https://www.desertlinktours.com/api/hotels/HotelData/${hotel}`
				);
				this.hotelid = response.data[0].HotelID;
				this.rating = response.data[0].Rating;
				this.address = response.data[0].Street + ", " + response.data[0].Area + ", " + response.data[0].District + ", " + response.data[0].City + ", " + response.data[0].Country;
				this.description = response.data[0].HotelDescription;
				this.locationRating = response.data[0].RatingLocation;
				this.roomRating = response.data[0].RatingRoom;
				this.serviceRating = response.data[0].RatingService;
				this.vat = response.data[0].VAT;
				this.taxes = response.data[0].Taxes;
				this.servicefees = response.data[0].ServiceFees;
				this.tourismdirham = response.data[0].TourismDirham;

				this.getHotelImages(this.hotelid);
				this.getHotelImagesCount(this.hotelid);
				this.getHotelRoom(this.hotelid);
				this.getRoomBasis(this.hotelid);
				this.getSpecialDate(this.hotelid);
				this.getHotelAmenities(this.hotelid);
				this.getHotelFacilities(this.hotelid);
				this.getHotelAttraction(this.hotelid);
				//console.log(this.name.split("-").join(" "));
				// for(let i=0 ;i<=this.da.length;i++) {
				//  this.dat.push(this.da[i]["HotelName"],this.da[i]["Location"],this.da[i]["city"],this.da[i]["district"],this.da[i]["country"]);
				//}
				//console.log(this.dat);
				//console.log(this.da);
			} catch (err) {
				console.log(err);
			}
		},
		async getHotelImages(hotel) {
			try {
				const response = await axios.get(
					`https://www.desertlinktours.com/api/hotels/HotelImages/${hotel}`
				);
				this.img1 = response.data[0].Image;
				//console.log(response.data.length)
				if (response.data.length < 8) {
					this.img2 = this.img1;
					this.img3 = this.img1;
					this.img4 = this.img1;
					this.img5 = this.img1;
					this.img6 = this.img1;
					this.img7 = this.img1;
					this.img8 = this.img1;
				}
				this.img2 = response.data[1].Image;
				this.img3 = response.data[2].Image;
				this.img4 = response.data[3].Image;
				this.img5 = response.data[4].Image;
				this.img6 = response.data[5].Image;
				this.img7 = response.data[6].Image;
				this.img8 = response.data[7].Image;
				//console.log(this.name.split("-").join(" "));
				// for(let i=0 ;i<=this.da.length;i++) {
				//  this.dat.push(this.da[i]["HotelName"],this.da[i]["Location"],this.da[i]["city"],this.da[i]["district"],this.da[i]["country"]);
				//}
			} catch (err) {
				console.log(err);
			}
		},
		async getHotelImagesCount(hotel) {
			try {
				const response = await axios.get(
					`https://www.desertlinktours.com/api/hotels/HotelImagesCount/${hotel}`
				);
				this.noofimages = response.data[0].noofimages;
			} catch (err) {
				console.log(err);
			}
		},
		async getRoomAmenities() {
			try {
				const response = await axios.get(
					`https://www.desertlinktours.com/api/hotels/RoomAmenities`
				);
				this.roomamenities = response.data;
				for (let i = 0; i <= this.roomamenities.length; i++) {
					this.room1.push(this.roomamenities[i]["AmenityName"]);
					//console.log(this.item[i]["Locaton"]);
					//console.log(this.room1); 
					//console.log(this.ra);
				}
			} catch (err) {
				console.log(err);
			}
		},
		async getRoomBasis(hotel) {
			try {
				const response = await axios.get(
					`https://www.desertlinktours.com/api/hotels/RoomBasis/${hotel}`
				);
				//this.roomamenities = response.data;
				for (let i = 0; i < response.data.length; i++) {
					this.roombasis.push(response.data[i]["RoomBasisName"]);
				}
				// console.log(this.roombasis);
			} catch (err) {
				console.log(err);
			}
		},
		async getHotelAttraction(hotel) {
			try {
				const response = await axios.get(
					`https://www.desertlinktours.com/api/hotels/HotelAttraction/${hotel}`
				);
				this.hotelattraction = response.data;
				//console.log(this.roombasis);
			} catch (err) {
				console.log(err);
			}
		},
		async getHotelFacilities(hotel) {
			try {
				const response = await axios.get(
					`https://www.desertlinktours.com/api/hotels/HotelFacilities/${hotel}`
				);
				this.hotelfacilities = response.data;
				//console.log(this.roombasis);
			} catch (err) {
				console.log(err);
			}
		},
		async getHotelAmenities(hotel) {
			try {
				const response = await axios.get(
					`https://www.desertlinktours.com/api/hotels/HotelAmenities/${hotel}`
				);
				this.hotelamenities = response.data;
				//console.log(this.roombasis);
			} catch (err) {
				console.log(err);
			}
		},
		async getSpecialDate(hotel) {
			//let a,b;
			try {
				const response = await axios.get(
					`https://www.desertlinktours.com/api/hotels/SpecialDate/${hotel}`
				);
				this.sp_checkin = response.data[0].StartDate;
				this.sp_checkout = response.data[0].EndDate;
				//console.log(this.guestData.checkinDate,this.guestData.checkoutDate);
				//console.log(a,b);
				//var sp= (Date.parse(this.sp_checkout)-Date.parse(this.sp_checkin))/(1000*3600*24);
				//var u= (Date.parse(this.guestData.checkoutDate)-Date.parse(this.guestData.checkinDate))/(1000*3600*24);
				//console.log(sp,this.guestData.days);
				let t = 1000 * 3600 * 24;
				let a = Date.parse(this.sp_checkin) / t;
				let b = Date.parse(this.sp_checkout) / t;
				let c = Date.parse(this.guestData.checkinDate) / t;
				let d = Date.parse(this.guestData.checkoutDate) / t;
				//console.log(a,b,c,d)
				if ((a >= c) && (b <= d)) {
					//console.log(b-a);
					if ((b - a) > 1) {
						this.specialDays = b - a;
					}
					else {
						this.specialDays = 1;
					}
				}
				else if ((c >= a) && (d <= b)) {
					//console.log(d-c);
					if ((d - c) > 1) {
						this.specialDays = d - c;
					}
					else {
						this.specialDays = 1;
					}
				}
				else if ((c <= a) && (b >= d) && (d >= a)) {
					this.specialDays = d - a;
					//console.log(d-a);
				}
				else if ((b >= c) && (b <= d)) {
					this.specialDays = b - c;
					//console.log(b-c);
				}
				//else{
				//console.log(this.guestData.days);
				//}
			} catch (err) {
				console.log(err);
			}
		},
		/*
		 SelectRow(index,e) {
			//console.log(index,e.target.value);
			//let total = 0;
			if (e) {
				//console.log(index,e);
				//console.log(this.data[index].Price);
				//console.log(this.v_rb);
				//console.log(this.v_rq);
				if(index==this.q_i){
					this.total = this.total + (this.data[index].Price * this.q_v);
				}
				else{
					this.total = this.total + (this.data[index].Price * this.v_rq);
				}
				//console.log(typeof(this.data[index].Price));
				//console.log(typeof(this.v_rq));
				console.log("check-",this.total);
				//this.status=true;
				//console.log(this.status);
				this.count++;
				//console.log(this.count);
				//this.total_table.push(this.total);
				this.total_table=[0];
				this.tax=(this.total * 5) / 100;
				this.total_price=Math.round(this.guestData.days)*((this.total) + this.tax);
				//console.log(this.total_table);
			}
			else{
				if(index==this.q_i){
					this.total = this.total - (this.data[index].Price * this.q_v);
				}
				else{
					this.total = this.total - (this.data[index].Price * this.v_rq);
				}
				console.log("un-",this.total)
				//this.status=false;
				//console.log(this.status);
				this.count--;
				if(this.count==0)
				{
					this.total=0;
					this.tax=0;
					this.total_price=0;
				}
				this.tax=(this.total * 5) / 100;
				this.total_price=Math.round(this.guestData.days)*((this.total) + this.tax);
				//console.log(this.count);
				//this.total_table.pop();
				this.total_table=[0];
				//console.log(this.total_table);
			}
				//console.log(e);
			
		},*/
		SelectQ(i, e) {
			//console.log(e);
			this.q_i = i;
			this.q_v = e.target.options[e.target.options.selectedIndex].value;
			// console.log(this.q_v);
			/* if(e.target.options.selectedIndex > -1) {
					  console.log(e.target.options[e.target.options.selectedIndex].value);
					  console.log(i);
			  }*/
			//console.log(this.data[index]);
			//console.log(this.data[index].Action2.rb);
			//this.delPackage(this.items[index].PckgId);
		},
		SelectRb(i, e) {
			//console.log(e);
			this.q_i = i;
			this.v_rb = e.target.options[e.target.options.selectedIndex].value;
			// console.log(this.v_rb);
		},
		handleSelectionChange(selection) {
			//console.log(selection[0].RoomID);
			//console.log(selection[0].SpecialPrice);
			let t1 = 0;//for total normal price
			let t2 = 0;//for total normal price
			let t3 = 0;//for total special price
			let t4 = 0;//for total special price
			let count = 0;
			for (let i = 0; i < selection.length; i++) {
				//console.log(i);
				if (selection[i].RoomQuantity > 1 && selection[i].RoomQuantity < 11) {
					t1 = t1 + (selection[i].RoomQuantity * selection[i].Price);
					t3 = t3 + (selection[i].RoomQuantity * selection[i].SpecialPrice);
					count = count + selection[i].RoomQuantity;
					this.noofperson = this.noofperson + (selection[i].NoOfPerson * selection[i].RoomQuantity);
					//console.log(selection[i].Action1);
					//console.log(selection[i].Price);
					//console.log(t1);
				}
				else {
					//console.log("else"+1);
					t2 = t2 + selection[i].Price;
					t4 = t4 + selection[i].SpecialPrice;
					count = count + 1;
					this.noofperson = this.noofperson + selection[i].NoOfPerson;
					//console.log(t1);
				}

			}
			//console.log(count);

			this.RoomCount = count;
			this.total_table = [0];
			let date_diff = this.guestData.days - this.specialDays;
			this.total = ((t1 + t2) * (date_diff)) + ((t3 + t4) * (this.specialDays));
			//console.log(this.specialDays,date_diff);
			this.tax = ((this.total) * ((this.vat + this.taxes + this.servicefees) / 100)) + this.tourismdirham;
			this.total_price = this.total + this.tax;
			this.roomdetail = selection;
			console.log(this.roomdetail);
		},
		open() {
			let guest = this.guestData.adult + this.guestData.children;
			if (this.total == 0) {
				this.$message({
					type: 'warning',
					message: 'No Room is selected'
				});
			}
			else if (this.noofperson < (guest)) {
				this.$message({
					type: 'warning',
					message: 'Room capacity is insufficent for ' + guest + ' guests'
				});
			}
			else {
				this.$confirm('Are you sure to confirm this booking?', 'Warning', {
					confirmButtonText: 'Yes',
					cancelButtonText: 'No',
					type: 'warning'
				}).then(() => {
					this.postBooking();


					this.$message({
						type: 'success',
						message: 'Booking Confirmed'
					});
					this.getBookingID();
					//console.log(this.bookingid);
					//this.postRoomDetail();
					this.$router.push('/reservation');
				}).catch(() => {
					this.$message({
						type: 'error',
						message: 'Booking cancelled'
					});
				});
			}
		},
	}
};
</script>

<style scoped>
svg {
	margin-right: 10px;
	height: 20px;
	width: 18px;
}

.h_3 {
	margin-left: 30px;
}

li {
	list-style-type: none;
	padding: 5px 0;
}

.abc {
	margin-top: 5px;
	width: 215px;
	height: 160px;
	background-image: url('../assets/nomad7.jpg');
	color: #fff;
	font-size: 1.25em;
	font-weight: bold;
	text-align: center;
	cursor: pointer;
	background-size: 100% 100%;
	padding: 5px;
}

.main {
	background-color: #f5f5f5;
	padding: 120px 70px 50px 70px;
	min-height: 100vh;
}

.main1 {
	min-height: 100vh;
	display: grid;
	column-gap: 30px;
	grid-template-columns: repeat(4, 1fr);
	background-color: #f5f5f5;
	padding: 120px 70px 50px 70px;
}

.carousel {
	width: 50%;
}

.book-btn {
	text-decoration: none;
	padding: 20px 100px;
	color: white;
	background-color: #1a4a8d;
	border: none;
}

.book-btn:hover {
	background-color: #043580;
}

.hotel-name {
	font-size: 25px;
	font-weight: 700;
}

.hotel-location {
	font-size: 14px;
	font-weight: 400;
}

.booking-details {
	border: 1px solid rgb(197, 197, 197);
	border-radius: 3px;
}

.heading {
	font-size: 24px;
	font-weight: 600;
	color: rgb(51, 51, 51);
}

.details-heading {
	font-size: 14px;
	font-weight: 700;
}

.details-body {
	font-size: 16px;
	font-weight: 700;
	color: rgb(0, 113, 194);
}

small {
	font-size: 14px;
	font-weight: 400;
	color: #6b6b6b;
}

.search-area {
	top: 120px;
	background-color: white;
	width: 270px;
	height: 425px;
	padding: 20px 20px;
	border-radius: 3px;
	outline-style: solid;
	outline-color: black;
}

.card-container {
	grid-column-start: 2;
	grid-column-end: 5;
}

.search-input {
	height: 36px;
	background-color: whitesmoke;
	border-radius: 2px;
}

.search-btn {
	width: 100%;
	height: 50px;
	border: none;
	border-radius: 2px;
	outline: none;
}

button {
	background-color: black;
	color: white;
}

button:hover {
	color: white;
	background-color: #E4A700;
}

label,
small {
	font-size: 12px;
	font-weight: 400;
}

h2 {
	font-size: 20px;
	font-weight: 600;
	color: rgb(51, 51, 51);
}

.result {
	font-size: 14px;
	font-weight: 400;
}

.header {
	font-size: 24px;
	font-weight: 700;
}

@media only screen and (max-width: 1200px) {
	.main {
		grid-template-columns: 1fr;
	}

	.search-area {
		display: none;
	}

	.card-container {
		grid-column-start: 1;
		grid-column-end: 2;
	}
}

.el-button {
	font-weight: bold;
}
</style>
<style>
/*Modify Table background color and text colors*/
.el-table da,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
	background-color: #66d9ff;
	color: black;
	font-size: 14px;
	flex: auto;
}

/*Modify the highlight of the Element-UI's Table in the mouse hover HOVER*/
.el-table tbody tr:hover>td {
	background-color: #e6fff8;

}
</style>